@import './asset/scss/themes-vars.module';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "PoppinsVariableFont";
  src: local("poppinsVariableFont"),
    url("./asset/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.customInput_padding>.MuiInputBase-root {
  padding: 5px 10px;

}

.customInput_padding>.MuiInputBase-root::before {
  border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding>.MuiInputBase-root::after {
  border-bottom: 2px solid $primaryMain !important;
}

.customInput_padding>.MuiInput-root.Mui-error::before {
  border-bottom: 2px solid $errorMain !important;
}

.customInput_padding>.MuiInput-root.Mui-error::after {
  border-bottom: 2px solid $errorMain !important;
}

.swal2-container {
  z-index: 99999;
}

.swal2-confirm,
.swal2-cancel {
  outline: none !important;
  box-shadow: none !important;
}

.profile-step {
  .MuiStepLabel-root .Mui-completed {
    color: green !important;
  }

  .MuiStepLabel-label.Mui-completed.MuiStepLabel-aternativeLabel {
    color: grey.500 !important;
  }

  .MuiStepLabel-root .Mui-active {
    color: #547DBF;
  }

  .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
    color: white !important;
  }

  .MuiStepLabel-root .Mui-active .MuiStepIcon-text {
    fill: white !important;
  }

  .MuiStepLabel-labelContainer>span {
    font-size: 1.2rem !important;
    font-weight: 550 !important;
    padding: 2rem 0 !important;
  }

  .MuiStepLabel-iconContainer .MuiSvgIcon-root {
    font-size: 2.5rem !important;
  }
}

/* legacy search button design */
.legacy-search {
  width: 12%;
  display: flex;
}

.Textarea_design{
  font-weight: 400;
  font-family: PoppinsVariableFont;
  padding: 12px;
  max-height: 124px;
  resize: none;
  overflow-y: auto !important;
  // background: #4091d60d !important;
  border-radius: 5px;
  border: 1px solid $defaultBorderColor;
}

.Textarea_design:focus-visible {
  border: 1px solid $defaultBorderColor !important;
  outline: none !important;
}

.legacy-search-div {
  cursor: pointer;
  width: 100%;
  height: 3.1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 6px;
  margin-top: 2.6rem;
  border: 1px solid #ccc;
  background-color: #dcdbdf;
}

/* Styles for screens with a width between 481 and 960 pixels */
@media screen and (min-width: 360px) and (max-width: 960px) {
  .profile-step {
    .MuiStepLabel-label {
      display: flex !important;
    }

    .MuiStepLabel-root .Mui-completed {
      color: green !important;
    }

    .MuiStepLabel-label.Mui-completed.MuiStepLabel-aternativeLabel {
      color: grey.500 !important;
    }

    .MuiStepLabel-root .Mui-active {
      color: #547DBF;
    }

    .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
      color: white !important;
    }

    .MuiStepLabel-root .Mui-active .MuiStepIcon-text {
      fill: white !important;
    }

    .MuiStepLabel-labelContainer>span {
      font-size: .75rem !important;
      font-weight: 500 !important;
      padding: 0.2rem 0 !important;
      display: flex !important;
      justify-content: center !important;
    }

    .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel {
      color: #547DBF !important;
    }

    .MuiStepLabel-iconContainer .MuiSvgIcon-root {
      font-size: 1.5rem !important;
    }
  }
  .css-15pdb31-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 0;
  }
}